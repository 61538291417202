import React from "react"
import Token from "./utils/token"
import AlignLogin from "./components/AlignLogin"
import Box from "@material-ui/core/Box"
import CircularProgress from "@material-ui/core/CircularProgress"

import Info from "./utils/info"
import Tips from "./components/Tips"

function CheckStatus(Component) {
  return class extends React.Component {
    constructor(props) {
      super(props)
      this.state = {
        isLoading: true,
      }
    }

    componentDidMount() {
      if (!Token.get()) {
        AlignLogin().create()
      } else {
        this.getAccountInfo()
      }
    }
    async getAccountInfo() {
      try {
        // eslint-disable-next-line
        const { error, data } = await $Api.default.getInfo({
          type: "personal",
        })
        const { code, msg } = error
        // eslint-disable-next-line
        if (code) throw { message: msg }
        Info.save(data)
      } catch (err) {
        Tips().create(err.message)
      } finally {
        this.setState({
          isLoading: false,
        })
      }
    }

    render() {
      const { isLoading } = this.state
      if (isLoading && Token.get()) {
        return (
          <Box display="flex" width="10rem" height="100%" position="fixed" alignItems="center" justifyContent="center" flexDirection="row">
            <CircularProgress></CircularProgress>
          </Box>
        )
      }

      if (!isLoading && Token.get()) {
        return <Component></Component>
      }

      return <Box></Box>
    }
  }
}
export default CheckStatus
