import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom"
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import ArrowBackIos from "@material-ui/icons/ArrowBackIos";
import moment from "moment";
import { Switch, TimePicker, Button } from 'antd';
// import { white } from '@material-ui/core/colors';
import Tips from "../../../components/Tips";

import Popup from "../../../components/PopupWx"
import PopupDate from "../../../components/PopupDate"

function isWeiXin() {
    var ua = window.navigator.userAgent.toLowerCase();
    if (ua.match(/MicroMessenger/i) == 'micromessenger') {
      return true;
    } else {
      return false;
    }
  }

function getQueryString(name) {
    var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i');
    var r = window.location.search.substr(1).match(reg);
    if (r != null) {
        return unescape(r[2]);
    }
    return null;
}

let isSend = false
let defaultTime = '12:00'

function SubScription(props) {

    const [isCheck, setIsCheck] = useState(false)
    const [clockTime, setClockTime] = useState("12:00")
    const [isWx, setIsWx] = useState(false)

    useEffect(() => {
        checkSubscribe()

        if (isWeiXin()) {
            setIsWx(true)
            if (getQueryString('code')) {
                bindWx()
            } else {
                checkWxBind()
            }
        } else {
            Tips().create("订阅功能仅支持在微信中操作", "info")
        }
    }, [])

    async function checkSubscribe () {
        try {
            const { error, data } = await $Api.default.checkSubscribe()
            const { code } = error
            if (code) throw new Error(code)
            if (data) {
                const { clock_time } = data
                isSend = true
                defaultTime = clock_time
                setIsCheck(true)
                setClockTime(clock_time)
            }
        } catch (err) {
            console.log(err)
        }
    }

    async function bindSubscribe () {
        try {
            const { error } = await $Api.default.bindSubMessage({
                clock_time: clockTime,
                is_send: isSend === isCheck ? 0 : 1
            })
            const { code } = error
            if (code) throw new Error(code)
            isSend = isCheck
            defaultTime = clockTime

            if (isCheck) {
                Tips().create("订阅成功", 'info')
            } else {
                Tips().create("取消订阅成功", 'info')
            }
        } catch (err) {
            // console.log(err)
            if (+err.message === 3005) {
                setIsCheck(isSend)
                setClockTime(defaultTime)
                Popup.create()
            }
        }
    }

    async function bindWx() {
        try {
            const { error: { code } } = await $Api.default.bindWx({ 
                code: getQueryString('code')
            })
            if (code) throw new Error(code)
            Tips().create('微信授权成功', 'success')
        } catch (err) {
            console.log(err)
            if (+err.message === 3002) {
                Tips().create('微信授权失败！')
            }
            if (+err.message === 3004) {
                Tips().create('未绑定公众号')
            }
        }
    }

    async function checkWxBind() {
        try {
            const { error: { code } } = await $Api.default.checkWxBind()
            if (code) throw new Error(code)
        } catch (err) {
            if (isWeiXin()) {
                window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx78dccce4816e1721&redirect_uri=${encodeURIComponent(window.location.href)}&response_type=code&scope=snsapi_base&state=213#wechat_redirect`
            }
        }
    }

    const openTime = () => {
        PopupDate.create({
            date: clockTime,
            cb (hours, seconds) {
                console.log()
                setClockTime(`${hours}:${seconds}`)
            }
        })
    }

    return (
        <Box className="subdescription-wrapper" position="fixed" width={1} height="100%" display="flex" flexDirection="column" flexWrap="nowrap" justifyContent="flex-start" alignItems="flex-start" >
        <Box 
            style={{
                backgroundColor: `rgb(159, 200, 107)`,
                color: '#fff'
            }}
            fontSize={window.px2rem(32)} 
            p={2} 
            boxSizing="border-box" 
            textAlign="center" width={1} 
            zIndex={4}
            top={0}
            left={0}
            position="fixed">
          <Box position="absolute" top={1} left={1}
            style={{
              width: window.px2rem(50),
              height: window.px2rem(50),
            }}
            onClick={() => props.history.replace('/home/2')}
          >
            <IconButton aria-label="delete" disabled color="primary">
              <ArrowBackIos/>
            </IconButton>
          </Box>
          订阅
        </Box>

            <Box width={1} display="flex" flexDirection="row" justifyContent="space-around" flexWrap="nowrap" pt={1} position="relative" >
                <div className="wx-msg-tips">
                    <p>微信消息提醒</p>
                    <Switch
                        checked={isCheck} 
                        onChange={val => setIsCheck(val)} size="default"></Switch>
                </div>
                <div className="wx-msg-tips">
                    <p>微信消息提醒</p>
                    <div onClick={openTime} className="subscr-timedate">{clockTime}</div>
                    {/* <TimePicker
                    defaultValue={moment(clockTime, "HH:mm")}
                    value={moment(clockTime, "HH:mm")}
                    format="HH:mm"
                    inputReadOnly={true}
                    onChange={(val) => {
                        setClockTime(moment(val, "HH:mm").format("HH:mm"))
                    }}
                    placeholder=""></TimePicker> */}
                </div>
            </Box>

{
    isWx &&
        <Box width={1} zIndex={4} position="fixed" left={0} bottom={0}>
            <Button 
                disabled={(isSend === isCheck && defaultTime === clockTime) }
                type="primary" 
                onClick={bindSubscribe} className="wx-correct-btn">确定</Button>
        </Box>
}

      </Box>
    )
}

export default withRouter(SubScription)