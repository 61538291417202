import React, { memo, useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Tips from "../../../components/Tips";

const useStyle = makeStyles(({ props }) => ({
  BottomNavigation: {
    height: "auto",
  },
  Grid: {
    background: "red",
    height: "100%",
  },
  List: {
    width: "100%",
    margin: "0 auto",
  },
  Paper: {
    background: "#eee",
    borderRadius: "8px",
  },
  ListItem: {
    height: props.px2rem(200),
    background: "#fff",
    borderRadius: "8px",
    marginTop: "10px",
    padding: "10px",
    boxSizing: "border-box",
    boxShadow: "0 5px 5px 0 #ccc",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  ContentBox: {
    overflow: "auto",
    background: "#e5e5e6",
  },
  Button: {
    fontSize: props.px2rem(24),
  },
}));

function Home() {
  const stylies = useStyle();
  const [isRequest, setRequest] = useState(0);
  const [course, setCourse] = useState([]);

  const getCourse = async () => {
    try {
      setRequest(0);
      // eslint-disable-next-line
      const { error, data } = await $Api.default.getCourse();
      const { code, msg } = error;
      if (code) throw { message: msg };
      console.log("data");
      console.log(data);
      setCourse(data || []);
      setRequest(1);
    } catch (err) {
      Tips().create(err.message);
      setRequest(2);
    } finally {
    }
  };

  useEffect(() => {
    getCourse();
  }, []);

  return (
    <>
      {isRequest === 0 && (
        <Box
          width={1}
          height="100%"
          display="flex"
          alignItems="center"
          justifyContent="center"
          position="absolute"
          zIndex="10"
          bgcolor="#e5e5e6"
        >
          <CircularProgress></CircularProgress>
        </Box>
      )}
      <Box
        width={0.95}
        display="flex"
        alignItems="flex-start"
        justifyContent="center"
      >
        <List className={stylies.List}>
          {(course || []).map((cur) => (
            <ListItem key={`${cur.id}`} className={stylies.ListItem}>
              <Box width={0.4}>
                <Box
                  width={1}
                  pt="60%"
                  className={stylies.Paper}
                  style={{
                    // eslint-disable-next-line
                    backgroundImage: `url(${$Global.image}${cur.cover})`,
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                  }}
                ></Box>
              </Box>
              <Box
                width={0.6}
                pl="10px"
                height="100%"
                display="flex"
                flexDirection="column"
                justifyContent="space-between"
              >
                <Typography
                  noWrap
                  style={{
                    fontSize: window.px2rem(32),
                  }}
                >
                  {cur.title}
                </Typography>
                <Box
                  width={1}
                  display="flex"
                  justifyContent="flex-end"
                  flexDirection="row"
                >
                  <Button
                    variant="contained"
                    className={stylies.Button}
                    color="primary"
                    size="small"
                    href={`?id=${cur.id}&?title=${encodeURIComponent(
                      cur.title
                    )}#content`}
                  >
                    开始学习
                  </Button>
                </Box>
              </Box>
            </ListItem>
          ))}
        </List>
      </Box>
    </>
  );
}

export default memo(Home);
