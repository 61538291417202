import React from "react";
import ReactDOM from "react-dom";
import Box from "@material-ui/core/Box";
import Alert from '@material-ui/lab/Alert';

function Tips() {
  return {
    create(message, type = "error") {
      let ele = document.createElement("div");
      document.body.appendChild(ele);
      const timer = setTimeout(() => {
        remove();
      }, 3000);

      const remove = () => {
        if (ele) {
          document.body.removeChild(ele);
          clearTimeout(timer);
          ele = null;
        }
      };
      ReactDOM.render(
      <Box position="fixed" bottom={window.px2rem(40)} style={{
        transform: "translateX(-50%)",
        left: "50%",
      }}>
        <Alert style={{
          width: "100%",
          fontSize: window.px2rem(24)
      }} 
      elevation={6}
      variant="filled"
      severity={type}>
        <Box position="relative" style={{whiteSpace: 'nowrap'}} top="4px">{message}</Box>
      </Alert>
      </Box>,
        ele
      );
    },
  };
}

export default Tips;
