import React, { memo, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import TextField from "@material-ui/core/TextField";
import CardActions from "@material-ui/core/CardActions";
import Button from "@material-ui/core/Button";
import Tips from "../../components/Tips";
import Loading from "../../components/Loading"
import Token from "../../utils/token"
import Info from "../../utils/info"

const useStyles = makeStyles(({ props }) => ({
  root: {
    height: "100vh",
  },
  GridItem: {
    margin: "0 auto",
  },
  Card: {
    boxShadow: "none",
    fontSize: 12,
  },
  CardActions: {
    padding: "0 16px",
    height: "1.1876rem",
    "& .MuiButtonBase-root": {
      fontSize: props.px2rem(38),
      height: "100%",
    },
  },
}));

function Login(props) {
  const stylies = useStyles();
  const [account, setAccount] = useState("");
  const [password, setPassword] = useState("");

  const onSubmit = async () => {
    let $loading = Loading()
    try {
      await formChecked()
      $loading.create()
      // eslint-disable-next-line
      const { error, data } = await $Api.default.uLogin({ account, password });
      const { code, msg } = error
      if (code) throw new Error(msg)
      const { token, user } = data
      Token.save(token)
      Info.save(user)
      props.history.replace("/home")
    } catch (err) {
      Tips().create(err.message);
      console.log(err);
    } finally {
      $loading.remove()
    }
  };
  const formChecked = () => {
    if (!account) {
      return Promise.reject("账号不能为空")
    }
    if (!password) {
      return Promise.reject("密码不能为空")
    }
    return Promise.resolve()
  };

  useEffect(() => {}, []);

  return (
    <Grid className={stylies.root} alignItems="center" container>
      <Grid item xs={11} className={stylies.GridItem}>
        <Card className={stylies.Card}>
          <Box width={window.px2rem(320)} m="0 auto" height={window.px2rem(160)} style={{
            border: "none",
            // eslint-disable-next-line
            backgroundImage: `url(${$Global.image}/logo)`,
            backgroundSize: "contain",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat"
          }}></Box>
          <CardContent>
            <Box>
              <TextField
                type="text"
                variant="outlined"
                label="账号"
                value={account}
                onChange={(e) => setAccount(e.target.value)}
                className={stylies.TextField}></TextField>
            </Box>
            <Box mt={2}>
              <TextField
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                variant="outlined"
                label="密码"></TextField>
            </Box>
          </CardContent>
          <CardActions className={stylies.CardActions}>
            <Button
              variant="contained"
              color="primary"
              disableElevation
              fullWidth
              onClick={onSubmit}
            >
              登录
            </Button>
          </CardActions>
        </Card>
      </Grid>
    </Grid>
  );
}

export default memo(Login);
