import "./App.scss";
import { Switch, HashRouter as Router, Route, Redirect } from "react-router-dom";

import CheckStatus from "./CheckStatus"
import Login from "./page/Login"
import Home from "./page/Home"
import Content from "./page/Content"
import Account from "./page/Account"
import SubScription from "./page/Home/components/SubScription"
import "antd/dist/antd.css";

function App() {
  return (
    <Router>
      <Switch>
        <Route component={Login} path="/login"></Route>
        <Route component={CheckStatus(Home)} path="/home/:id"></Route>
        <Route component={CheckStatus(Content)} path="/content"></Route>
        <Route component={CheckStatus(Account.Alter)} path="/personAlert"></Route>
        <Route component={CheckStatus(Account.OnlyRead)} path="/personRead"></Route>
        <Route component={CheckStatus(SubScription)} path="/sub_scription"></Route>
        <Route path="/home" extra>
          <Redirect from="/home" to="/home/0"></Redirect>
        </Route>
        <Route path="/" extra>
          <Redirect from="/home" to="/home/0"></Redirect>
        </Route>
          <Redirect from="/" to="/login"></Redirect>
      </Switch>
    </Router>
  );
}

export default App;
