import axios from "axios";
// import axios from "axios-cache";
import Tips from "../components/Tips";
import AlignLogin from "../components/AlignLogin";
import ResetLogin from "../components/ResetLogin";
import Token from "./token";

const $Request = {}

console.log('$Global:', $Global.requestBaseURL)
const AxiosInstance = axios.create({
  baseURL: $Global.requestBaseURL,
  port: 8088,
  timeout: 10000,
});

// eslint-disable-next-line
// AxiosInstance.get(`${$Global.requestBaseURL}/api/user`).then(rsp => {
//   console.log(rsp)
// })

AxiosInstance.interceptors.request.use(function (config) {
  console.log("AxiosInstance - rest", config)
  return config
})

AxiosInstance.interceptors.response.use(function (rsp) {
  // console.log("AxiosInstance - rsp:", rsp)
  return rsp
})

const requestApi = {
  uLogin: ["/api/login", "post"],
  uLogout: ["/api/logout"],
  getInfo: ["/api/user"],
  setInfo: ["/api/user", "post"],
  getCourse: ["/api/course"],
  getCourseDetail: ["/api/course/detail"],
  getResource: ["/api/resource"],
  getPDF: ["/pdf"],
  checkWxBind: ['/api/wechat/checkBind'],
  bindWx: ['/api/wechat/bind', 'post'],
  checkSubscribe: ['/api/subscribe/check'],
  bindSubMessage: ['/api/subscribe/message', 'post'],
  uploadImage: ["/api/upload/image", "post", {
    contentType: false,
    processData: false,
    headers: {
      "Content-Type": "multiple/form-data"
    }
  }],
};

const axiosError = (status, data) => {
  // eslint-disable-next-line
  switch (status) {
    case 401:
      const { error: { code } } = data
      switch (code) {
        case 6004:
          Tips().create("[401]: 身份信息已过期");
          Token.remove()
          AlignLogin().create();
          break
        case 6012:
          Token.remove()
          ResetLogin().create();
          break
        default: break
      }
      break;
  }
}

AxiosInstance.interceptors.request.use(
  function (config) {
    if (config.url !== requestApi.uLogin[0]) {
      const token = Token.get()
      if (!token) {
        // source.cancel()
        axiosError(401)
      } else {
        config.headers["Authorization"] = `Bearer ${token}`
      }
    }
    return config;
  },
  (err) => {
    return Promise.reject(err);
  }
);

AxiosInstance.interceptors.response.use(
  function (rsp) {
    return rsp.data;
  },
  (err) => {
    err.response &&
      axiosError(err.response.status, err.response.data)
    return Promise.reject(err);
  }
);

function Request({ cfg, data }) {
  const iconf = cfg[2] || {}
  const headers = {}
  iconf.headers && Object.assign(iconf.headers, headers)
  return AxiosInstance.request(Object.assign({
    method: cfg[1] || "get",
    url: cfg[0],
    baseURL: $Global.requestBaseURL,
    [!cfg[1] || cfg[1] === "get" ? "params" : "data"]: Object.assign(data || {}, {
      reqTime: Date.now()
    }),
    headers,
  }, iconf));
}

function initRequest() {
  for (let api in requestApi) {
    if (api !== "constructor") {
      Object.assign($Request, {
        [api]: (data) => Request({ data, cfg: requestApi[api] }),
      });
    }
  }
}

initRequest();
// eslint-disable-next-line
export default $Request;
