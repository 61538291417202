import React from "react";
import ReactDOM from "react-dom"
import ImageWx from "../assets/wx.png"
import "./style.scss"

function PopupWx({ remove }) {

    return (
        <div className="popup-wx">
            <div className="popup-mask" onClick={remove}></div>
            <div className="popup-content">
                <div className="wxchat-code">
                    <img src={ImageWx} alt="" />
                </div>
                <div className="wxchat-text">关注启学网校订阅课程通知</div>
            </div>
        </div>
    )
}

const popup = {
    create() {
        const el = document.createElement("div")
        document.body.appendChild(el)

        const remove = () => {
            if (el) {
                document.body.removeChild(el)
            }
        }

        ReactDOM.render(<PopupWx remove={remove}></PopupWx>, el)
    }
}

export default popup