import React, { useState, memo } from "react";
import Card from "@material-ui/core/Card";
import { withRouter } from "react-router-dom"
import { makeStyles } from "@material-ui/styles";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import HowToRegOutlined from "@material-ui/icons/HowToRegOutlined";
import KeyboardArrowRightOutlined from "@material-ui/icons/KeyboardArrowRightOutlined";
import BorderColorOutlined from "@material-ui/icons/BorderColorOutlined";
import NetworkWifiOutlined from "@material-ui/icons/NetworkWifiOutlined";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Avatar from "@material-ui/core/Avatar";
import Loading from "../../../components/Loading";
import CircularProgress from "@material-ui/core/CircularProgress";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { grey } from "@material-ui/core/colors";

import Token from "../../../utils/token";
import Info from "../../../utils/info";

const useStyle = makeStyles(({ props, palette }) => ({
  BoxAvatar: {
    fontSize: props.px2rem(42),
    fontWeight: 600,
  },
  Avatar: {
    width: props.px2rem(120),
    height: props.px2rem(120),
  },
  TypographyDesc: {
    fontSize: props.px2rem(30),
    margin: "10px 0",
  },
  CardActions: {
    flexDirection: "column",
    "& .MuiButtonBase-root": {
      fontSize: props.px2rem(30),
      height: "100%",
    },
  },
  LogContainer: {
    "& .MuiButtonBase-root": {
      fontSize: props.px2rem(38),
      height: "100%",
    },
  },
  LogoutButton: {
    color: "#fff",
    boxShadow: "none",
    backgroundColor: grey[400],
  },
}));

function PersonInfo(props) {
  const stylies = useStyle();
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  const [isRequest, setRequest] = useState(2)
  const [username] = useState(Info.getName());
  const [portrait] = useState(Info.getPortrait());

  const backSign = async () => {
    const $loading = Loading();
    try {
      $loading.create();
      setOpen(false);
      await $Api.default.uLogout()
      Token.remove();
      Info.remove();
      props.history.push("/login")
    } catch (err) {
    } finally {
      $loading.remove();
    }
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <Box fontSize={window.px2rem(32)} fontWeight={600}>
            是否退出登录
          </Box>
        </DialogTitle>
        <DialogContent>
          <DialogContentText component="div">
            <Box
              fontSize={window.px2rem(24)}
              maxWidth={window.px2rem(750)}
              minWidth={window.px2rem(600)}
            >
              您即将退出登录, 请确认
            </Box>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={backSign} color="primary">
            <Box fontSize={window.px2rem(24)}>退出登录</Box>
          </Button>
          <Button onClick={handleClose} color="primary" autoFocus>
            <Box fontSize={window.px2rem(24)}>取消</Box>
          </Button>
        </DialogActions>
      </Dialog>
      <Box width={0.95} m="10px auto 0">
        <Card
          style={{
            boxShadow: "0 0 10px 0 #ccc",
          }}
        >
          <CardContent>
            <Box display="flex" flexDirection="row" alignItems="center">
              <Avatar src={portrait} className={stylies.Avatar}></Avatar>
              <Box pl={1} className={stylies.BoxAvatar}>
                {username}
              </Box>
            </Box>
            <Typography
              variant="body2"
              className={stylies.TypographyDesc}
              color="textSecondary"
              component="p"
            >
            </Typography>
          </CardContent>
        </Card>
        <Box
          mt={2}
          className={stylies.CardActions}
          bgcolor="#fff"
          borderRadius={window.px2rem(8)}
          boxSizing="border-box"
          p={1}
        >
          <Button
            fullWidth
            size="small"
            color="inherit"
            href="#personRead"
            startIcon={<HowToRegOutlined></HowToRegOutlined>}
            endIcon={
              <KeyboardArrowRightOutlined color="inherit"></KeyboardArrowRightOutlined>
            }
          >
            <Box textAlign="left" height={window.px2rem(80)} lineHeight={window.px2rem(80)} color="#000" width={1}>
              个人信息
            </Box>
          </Button>
          <Button
            startIcon={
              <BorderColorOutlined
                style={{
                  width: window.px2rem(40),
                }}
              ></BorderColorOutlined>
            }
            endIcon={
              <KeyboardArrowRightOutlined color="inherit"></KeyboardArrowRightOutlined>
            }
            fullWidth
            size="small"
            href="#personAlert"
          >
            <Box textAlign="left" height={window.px2rem(80)} lineHeight={window.px2rem(80)} color="#000" width={1}>
              账号修改
            </Box>
          </Button>
          <Button
            startIcon={<BorderColorOutlined style={{width: window.px2rem(40)}}></BorderColorOutlined>}
            endIcon={<KeyboardArrowRightOutlined color="inherit"></KeyboardArrowRightOutlined>}
            fullWidth
            size="small"
            href="#sub_scription"
          >
            <Box 
              textAlign="left" 
              height={window.px2rem(80)} 
              lineHeight={window.px2rem(80)} 
              color="#000" width={1}>
              订阅
            </Box>
          </Button>
        </Box>
        <Box mt={4} height="1.1876rem" className={stylies.LogContainer}>
          <Button
            variant="contained"
            fullWidth
            size="small"
            onClick={() => setOpen(true)}
            className={stylies.LogoutButton}
          >
            退出登录
          </Button>
        </Box>
      </Box>
      {
        [0, 1].includes(isRequest) &&
        <Box bgcolor="#e5e5e6" position="absolute" left={0} top={0} width={1} height="100%" display="flex" flexDirection="row" justifyContent="center" alignItems="center">
          {
            isRequest === 0 &&
          <CircularProgress></CircularProgress>
          }
          {
            isRequest === 1 &&
            <Box textAlign="center" color="#999">
              <NetworkWifiOutlined></NetworkWifiOutlined>
              <Box fontSize={window.px2rem(32)}>请稍后重试</Box>
            </Box>
          }
        </Box>
      }
    </>
  );
}

export default memo(withRouter(PersonInfo));
