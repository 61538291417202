import React from "react";
import ReactDOM from "react-dom";
import { HashRouter } from "react-router-dom";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Slide from '@material-ui/core/Slide';

import Token from "../utils/token";
import Info from "../utils/info";

const router = new HashRouter();

function ResetLogin() {
  return {
    create() {
      // Token.remove()
      // Info.remove()

      const ele = document.createElement("div");
      document.body.appendChild(ele);
      const resetLoginEvt = () => {
        if (ele) {
          document.body.removeChild(ele)
          router.history.replace("/login");
        }
        // setTimeout(() => {
          // window.location.reload();
        // }, 0);
      };
      ReactDOM.render(
        <Box
          position="fixed"
          left="0"
          top="0"
          width={1}
          height="100%"
          bgcolor="rgba(0, 0, 0, 0.5)"
          zIndex={10}
          display="flex"
          flexDirection="column-reverse"
          alignItems="center"
        >
          <Slide in={true} direction="up">
            <Box
              py={window.px2rem(40)}
              position="absolute"
              display="flex"
              justifyContent="center"
              flexDirection="column"
              alignItems="center"
              width="10rem"
              borderRadius="10px 10px 0 0"
              bgcolor="#fff"
            >
              <Box mb={window.px2rem(40)} fontSize={window.px2rem(40)}>账号在其他地方登陆</Box>
              <Button
                fullWidth
                color="primary"
                variant="contained"
                onClick={resetLoginEvt}
                style={{
                  fontSize: window.px2rem(30),
                  width: "80%"
                }}
              >
                重新登录
              </Button>
            </Box>
          </Slide>
        </Box>,
        ele
      );
    },
  };
}

export default ResetLogin;
