export const image = "http://h5.api.qixue188.com"
export const requestBaseURL = "http://h5.api.qixue188.com"
export const filePath = "http://h5.api.qixue188.com"

// export const image = "http://api.qixueh5t.clashpig.cn/"
// export const requestBaseURL = "http://api.qixueh5t.clashpig.cn/"
// export const filePath = "http://api.qixueh5t.clashpig.cn/"

// export const image = "http://192.168.0.142:8088"
// export const requestBaseURL = "http://192.168.0.142:8088"
// export const filePath = "http://192.168.0.142:8088"