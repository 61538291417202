import { createMuiTheme } from '@material-ui/core/styles'


const px2rem = window.px2rem = function (data) {
  return `${data / 75}rem`
}
export default createMuiTheme({
  palette: {
    primary: {
      main: "#2196f3"
    }
  },
  props: {
    px2rem
  },
  overrides: {
    MuiFormLabel: {
      root: {
        fontSize: px2rem(30)
      }
    },
    MuiFormControl: {
      root: {
        width: '100%'
      }
    },
    MuiFormHelperText: {
      root: {
        fontSize: px2rem(18)
      }
    },
    MuiInputBase: {
      root: {
        fontSize: px2rem(30)
      }
    },
    MuiButton: {
      label: {
        fontSize: "inherit"
      },
      fullWidth: {
        height: "100%" 
      }
    },
    MuiSvgIcon: {
      root: {
        width: `${px2rem(50)} !important`,
        height: `${px2rem(50)} !important`,
        cursor: "pointer"
      }
    },
    MuiBottomNavigationAction: {
      label: {
        fontSize: px2rem(30),
        "&.Mui-selected": {
          fontSize: px2rem(30)
        }
      }
    },
    MuiCard: {
      root: {
        width: "100%"
      }
    },
    MuiAccordionSummary: {
      root: {
        minHeight: "auto",
        "&.Mui-expanded": {
          minHeight: "auto"
        }
      }
    },
    MuiChip: {
      label: {
        fontSize: px2rem(24)
      },
      icon: {
        width: `${px2rem(35)} !important`,
        height: `${px2rem(35)} !important`,
        "& .MuiSvgIcon-root": {
          width: `${px2rem(25)} !important`,
          height: `${px2rem(25)} !important`,
        }
      }
    },
    MuiTypography: {
      body1: {
        fontSize: px2rem(32)
      }
    }
  }
})
