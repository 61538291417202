import React, { memo, useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import ArrowBackIos from "@material-ui/icons/ArrowBackIos";
import CircularProgress from "@material-ui/core/CircularProgress";
import NetworkWifiOutlined from "@material-ui/icons/NetworkWifiOutlined";

import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Avatar from "@material-ui/core/Avatar";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";

import Tips from "../../components/Tips";
import Loading from "../../components/Loading";
import Info from "../../utils/info";

const useStyle = makeStyles(({ props }) => ({
  BottomNavigation: {
    height: "auto",
  },
  List: {
    width: "100%",
    margin: "0 auto",
  },
  TextBox: {
    overflow: "hidden",
  },
  ListItem: {
    background: "#fff",
    marginTop: "10px",
    overflow: "hidden",
  },
  ContentBox: {
    background: "#e5e5e6",
  },
  RadioGroup: {
    flexDirection: "row",
  },
  ButtonContainer: {
    height: "1.1876rem",
    "& .MuiButtonBase-root": {
      fontSize: props.px2rem(38),
      height: "100%",
    },
  },
}));

function AlterAccount(props) {
  const stylies = useStyle();
  const [disabled] = useState(props.disabled || false);
  const [personalInfo, setPersonalInfo] = useState({});
  const [isRequest, setRequest] = useState(0);
  const [ikey, setIKey] = useState(1)

  useEffect(() => {}, [personalInfo]);

  const upload = async (image) => {
    const $loading = Loading();
    try {
      $loading.create()
      const fd = new FormData();
      fd.append("image", image);
      // eslint-disable-next-line
      const { error, data } = await $Api.default.uploadImage(fd);
      const { code, msg } = error;
      // eslint-disable-next-line
      if (code) throw { message: msg };
      const { url } = data;
      setPersonalInfo((info) =>
        Object.assign(info, {
          portrait: url,
        })
      );
      setIKey(cut => cut + 1)
      setRequest(2);
    } catch (err) {
      Tips().create(err.message);
    } finally {
      $loading.remove()
    }
  };

  const uploadImageEvt = (e) => upload(e.target.files[0]);

  const formValueChange = (field, data) =>
    setPersonalInfo((info) =>
      Object.assign(info, {
        [field]: data,
      })
    );

  const formSubmit = async () => {
    const $loading = Loading();
    try {
      await formValite()
      $loading.create();
      // eslint-disable-next-line
      const { error } = await $Api.default.setInfo({
        type: "account",
        ...personalInfo,
      });
      const { code, msg } = error;
      if (code) throw new Error(msg)
      Tips().create("修改成功", "success")
    } catch (err) {
      Tips().create(err.message)
    } finally {
      $loading.remove()
    }
  };

  const formValite = async () => {
    if (!personalInfo.nick_name) throw { message: "名字不允许为空" }
    if (!personalInfo.phone) throw { message: "手机号不允许为空" }
    if (personalInfo.phone && !/1[3|5|7]\d{9}$/ig.test(personalInfo.phone)) throw { message: "请输入正确的手机号码" }
    if (personalInfo.email && !/^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/.test(personalInfo.email)) throw { message: "请输入正确的邮箱" }
  }

  const getAccountInfo = async () => {
    try {
      const { error, data } = await $Api.default.getInfo({
        type: "account",
      });
      const { code, msg } = error;
      if (code) throw new Error(msg)
      setPersonalInfo(data);
      setRequest(2);
    } catch (err) {
      Tips().create(err.message);
      setRequest(1);
    }
  };

  useEffect(() => {
    getAccountInfo();
  }, []);

  return (
    <>
      <Box
        height="100%"
        display="flex"
        flexDirection="column"
        flexWrap="nowrap"
        justifyContent="center"
        alignItems="flex-start"
        >
        <Box
          fontSize={window.px2rem(32)}
          p={2}
          boxSizing="border-box"
          textAlign="center"
          width={1}
          position="relative"
          >
          <Box
            position="absolute"
            top={1}
            left={1}
            style={{
              width: window.px2rem(50),
              height: window.px2rem(50),
            }}
            onClick={() => props.history.goBack()}
          >
            <IconButton aria-label="delete" disabled color="primary">
              <ArrowBackIos />
            </IconButton>
          </Box>
          账户资料
        </Box>
        <Box
          width={1}
          height="100%"
          overflow="hidden"
          pt={1}
          position="relative"
          flexGrow={1}
          className={stylies.ContentBox}
        >
          {[0, 1].includes(isRequest) && (
            <Box
              pt={4}
              left="0"
              top="0"
              zIndex="999"
              position="absolute"
              height="100%"
              width={1}
              bgcolor="#e5e5e6"
              display="flex"
              flexDirection="row"
              justifyContent="center"
              alignItems="center"
            >
              {isRequest === 0 && <CircularProgress></CircularProgress>}
              {isRequest === 1 && (
                <Box textAlign="center" color="#999">
                  <NetworkWifiOutlined></NetworkWifiOutlined>
                  <Box fontSize={window.px2rem(32)}>请稍后重试</Box>
                </Box>
              )}
            </Box>
          )}
          {isRequest === 2 && (
            <Box
              w={1}
              height="100%"
              display="flex"
              flexDirection="row"
              justifyContent="center"
              mt={-1}
              pt={1}
              pb={3}
              bgcolor="#fff"
            >
              <Grid item xs={11}>
                <Box mt={2}
                >
                  <FormControl>
                    <FormLabel>头像</FormLabel>
                    <Box
                      mt={1}
                      position="relative"
                      overflow="hidden"
                      width={window.px2rem(150)}
                      height={window.px2rem(150)}
                    >
                      <Avatar
                        // eslint-disable-next-line
                        src={$Global.image + personalInfo.portrait}
                        variant="circle"
                        key={ikey}
                        style={{
                          position: "absolute",
                          left: 0,
                          top: 0,
                          width: window.px2rem(150),
                          height: window.px2rem(150),
                        }}
                      ></Avatar>
                      <input
                        onChange={uploadImageEvt}
                        type="file"
                        style={{
                          display: "inline-block",
                          width: window.px2rem(150),
                          height: window.px2rem(150),
                          border: "40px solid transparent",
                          position: "absolute",
                          left: 0,
                          top: 0,
                          opacity: 0,
                        }}
                      />
                    </Box>
                  </FormControl>
                </Box>

                <Box mt={2} >
                  <TextField
                    type="text"
                    variant="outlined"
                    label="账户名"
                    disabled={true}
                    defaultValue={personalInfo.account}
                  ></TextField>
                </Box>

                <Box mt={2}>
                  <TextField
                    variant="outlined"
                    disabled={disabled}
                    label="昵称"
                    onChange={(e) =>
                      formValueChange("nick_name", e.target.value)
                    }
                    defaultValue={personalInfo.nick_name}
                  ></TextField>
                </Box>

                <Box mt={2}>
                  <TextField
                    variant="outlined"
                    disabled={disabled}
                    label="手机号"
                    onChange={(e) => formValueChange("phone", e.target.value)}
                    defaultValue={personalInfo.phone}
                  ></TextField>
                </Box>

                <Box mt={2}>
                  <TextField
                    variant="outlined"
                    disabled={disabled}
                    label="邮箱地址"
                    onChange={(e) => formValueChange("email", e.target.value)}
                    defaultValue={personalInfo.email}
                  ></TextField>
                </Box>

                <Box mt={2} className={stylies.ButtonContainer}>
                  <Button
                    fullWidth
                    color="primary"
                    onClick={formSubmit}
                    variant="contained"
                  >
                    提交
                  </Button>
                </Box>
              </Grid>
            </Box>
          )}
        </Box>
      </Box>
    </>
  );
}

function ReadOnlyAccount(props) {
  const stylies = useStyle();
  const [info, setInfo] = useState(Info.get() || {});

  const formValueChange = (field, data) =>
    setInfo((state) =>
      Object.assign(state, {
        [field]: data,
      })
    );

  const formSubmit = async () => {
    const $loading = Loading();
    try {
      await formValite()
      $loading.create();
      const cpInfo = Object.assign({}, info)
      delete cpInfo.portrait
      // eslint-disable-next-line
      const { error, data } = await $Api.default.setInfo({
        type: "personal",
        ...cpInfo,
      });
      const { code, msg } = error;
      if (code) throw { message: msg };
      Tips().create("修改成功", "success");
    } catch (err) {
      Tips().create(err.message);
    } finally {
      $loading.remove();
    }
  };

  const formValite = async () => {
    if (!info.name)  throw { message: "昵称不允许为空" }
    if (info.birthday && !/\d{4}-\d{2}-\d{2}$/ig.test(info.birthday.trim())) throw { message: "生日格式请按照yyyy-mm-dd格式输入" } 
    if (info.age <= 0 || (info.age && !/^\d+$/ig.test(info.age))) throw { message: "请输入正确的年龄" }
  }

  return (
    <>
      <Box
        height="100%"
        display="flex"
        flexDirection="column"
        flexWrap="nowrap"
        justifyContent="center"
        alignItems="flex-start"
        bgcolor="#fff"
      >
        <Box
          fontSize={window.px2rem(32)}
          p={2}
          boxSizing="border-box"
          textAlign="center"
          width={1}
          position="relative"
        >
          <Box
            position="absolute"
            top={1}
            left={1}
            onClick={() => props.history.goBack()}
          >
            <IconButton disabled color="primary">
              <ArrowBackIos
                style={{
                  width: window.px2rem(50),
                  height: window.px2rem(50),
                }}
              />
            </IconButton>
          </Box>
          个人信息
        </Box>
        <Box
          width={1}
          height="100%"
          pt={1}
          position="relative"
          flexGrow={1}
          className={stylies.ContentBox}
          >
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="center"
            bgcolor="#fff"
            mt={-1}
            pt={1}
            pb={2}
          >
            <Grid item xs={11}>
              <Box>
                <TextField
                  type="text"
                  variant="outlined"
                  label="名称"
                  disabled={true}
                  onChange={(e) => formValueChange("name", e.target.value)}
                  defaultValue={info.name}
                ></TextField>
              </Box>

              <Box mt={2}>
                <TextField
                  variant="outlined"
                  onChange={(e) => formValueChange("age", e.target.value)}
                  label="年龄"
                  defaultValue={info.age}
                ></TextField>
              </Box>

              <Box mt={2}>
                <FormControl component="fieldset">
                  <FormLabel component="legend">性别</FormLabel>
                  <RadioGroup
                    aria-label="gender"
                    onChange={(e) => formValueChange("sex", e.target.value)}
                    name="sex"
                    defaultValue={`${info.sex}`}
                    row
                  >
                    <FormControlLabel
                      value="1"
                      control={<Radio color="primary"/>}
                      label="男"
                    />
                    <FormControlLabel
                      value="2"
                      control={<Radio color="primary"/>}
                      label="女"
                    />
                    <FormControlLabel
                      value="0"
                      control={<Radio color="primary"/>}
                      label="保密"
                    />
                  </RadioGroup>
                </FormControl>
              </Box>

              <Box mt={2}>
                <TextField
                  variant="outlined"
                  onChange={(e) => formValueChange("birthday", e.target.value)}
                  label="生日"
                  defaultValue={info.birthday}
                ></TextField>
              </Box>

              <Box mt={2}>
                <TextField
                  onChange={(e) => formValueChange("address", e.target.value)}
                  variant="outlined"
                  label="地址"
                  defaultValue={info.address}
                ></TextField>
              </Box>

              <Box mt={2} className={stylies.ButtonContainer}>
                <Button
                  onClick={formSubmit}
                  fullWidth
                  color="primary"
                  variant="contained"
                >
                  提交
                </Button>
              </Box>
            </Grid>
          </Box>
        </Box>
      </Box>
    </>
  );
}

// eslint-disable-next-line
export default {
  Alter: memo(withRouter(AlterAccount)),
  OnlyRead: memo(withRouter(ReadOnlyAccount)),
};
