import React, { memo, useState, useEffect, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Fade from "@material-ui/core/Fade";
import CircularProgress from "@material-ui/core/CircularProgress";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import Backspace from "@material-ui/icons/Backspace";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";

import Tips from "../../../components/Tips";
import Hammer from "hammerjs";
import { useCallback } from "react";

const useStyle = makeStyles(({ props, palette }) => ({
  BottomNavigation: {
    height: "auto",
  },
  List: {
    width: "100%",
    margin: "0 auto",
  },
  TextBox: {
    overflow: "hidden",
    fontSize: props.px2rem(28),
  },
  ListItem: {
    background: "#fff",
    marginTop: "10px",
    overflow: "hidden",
  },
  AccordionDetails: {
    padding: 0,
    display: "block",
  },
  ContentBox: {
    overflow: "auto",
    background: "#e5e5e6",
  },
  Accordion: {
    boxShadow: "0 5px 5px 0 #ccc",
  },
  Button: {
    fontSize: props.px2rem(20),
    whiteSpace: "nowrap",
    marginLeft: props.px2rem(4),
    boxShadow: "none",
  },
  PreviewButton: {
    fontSize: props.px2rem(20),
    whiteSpace: "nowrap",
    marginLeft: props.px2rem(4),
    boxShadow: "none",
    // backgroundColor: palette.primary.light
  },
}));

const defaultPreviewScreen = {
  width: 0.7,
  height: "80%",
  borderRadius: "10px",
  full: true,
};

function isWeiXin() {
  var ua = navigator.userAgent.toLowerCase();
  var isWinxin = ua.indexOf("micromessenger") !== -1;
  if (isWinxin) {
    return true;
  } else {
    return false;
  }
}

let $defaultScale = 3;
let $previewPath = "";
let $previewTask = null;
let $page = 1;
let $allPage = 1;

function Home() {
  const stylies = useStyle();
  const [isRequest, setRequest] = useState([]);
  const [resourceId, setResourceId] = useState(0);
  const [course, setCourse] = useState([]);
  const [resource, setResource] = useState({});
  const [isOpenPreview, setIsOpenPreview] = useState(false);
  const [previewStatus, setPreviewStatus] = useState(0);
  const [wx, isWX] = useState(isWeiXin());
  const [previewPage, setPreviewPage] = useState(1);
  const [allPage, setAllPage] = useState(1);
  const [previewImageSrc, setPreviewImageSrc] = useState(null);
  const [previewScreen, setPreviewScreen] = useState(
    Object.assign({}, defaultPreviewScreen)
  );
  const previewBoxRef = useRef();
  const previewBoxWrapperRef = useRef();
  const previewImageRef = useRef();

  const getCourse = async () => {
    try {
      // eslint-disable-next-line
      const { error, data } = await $Api.default.getCourse();
      const { code, msg } = error;
      if (code) throw { message: msg };
      setCourse(data);
    } catch (err) {}
  };

  const getResource = async (id) => {
    if (resource[id]) return false;
    try {
      setRequest((sr) => sr.concat(id));
      // eslint-disable-next-line
      const { error, data } = await $Api.default.getResource({
        course_id: id,
      });
      const { code, msg } = error;
      if (code) throw { message: msg };
      setResource((srcc) => {
        return Object.assign(srcc, {
          [id]: data || null,
        });
      });
      setRequest((sr) => {
        const pos = sr.indexOf(id);
        sr.splice(pos, 1);
        return sr;
      });
      setResourceId((count) => count + 1);
    } catch (err) {
      Tips().create(err.message);
    }
  };

  useEffect(() => {
    getCourse();
  }, []);

  useEffect(() => {
    if (previewImageRef.current) {
      function point2D(x, y) {
        return { x: x, y: y };
      }

      var reqAnimationFrame = (function () {
        return (
          window[Hammer.prefixed(window, "requestAnimationFrame")] ||
          function (callback) {
            window.setTimeout(callback, 1000 / 60);
          }
        );
      })();

      var tMatrix = [1, 0, 0, 1, 0, 0]; //x缩放，无，无，y缩放，x平移，y平移
      var initScale = 1; //初始化scale
      var el = previewImageRef.current; //获取元素
      var mc = new Hammer.Manager(el);
      var ticking = false;
      var poscenter = point2D(0, 0); //缓存双指的中心坐标
      var duration = ""; //设置过渡效果，用于双击缩放效果
      var lastTranslate = point2D(0, 0); //记录上次的偏移值
      var lastcenter = point2D(el.offsetWidth / 2, el.offsetHeight / 2); //图像的中心点，用于对比双指中心点

      var center = lastcenter;
      mc.add(new Hammer.Pan({ threshold: 0, pointers: 1 }));
      mc.add(new Hammer.Pinch({ threshold: 0 }));
      mc.add(new Hammer.Tap({ event: "doubletap", taps: 2 }));
      mc.on("panmove", onPan);
      mc.on("panstart", onPanStart);
      mc.on("pinchmove", onPinch);
      mc.on("pinchstart", onPinchStart);
      mc.on("doubletap", onDoubleTap);

      function onPanStart(ev) {
        lastTranslate = point2D(tMatrix[4], tMatrix[5]); //缓存上一次的偏移值
      }
      function onPan(ev) {
        duration = "";
        el.className = "";
        tMatrix[4] = lastTranslate.x + ev.deltaX;
        tMatrix[5] = lastTranslate.y + ev.deltaY;
        requestElementUpdate("onpan");
      }
      function onPinchStart(ev) {
        duration = "";
        lastTranslate = point2D(tMatrix[4], tMatrix[5]); //记录上一次的偏移值
        initScale = tMatrix[0] || 1;
        poscenter = point2D(ev.center.x, ev.center.y);

        lastcenter = point2D(
          center.x + lastTranslate.x,
          center.y + lastTranslate.y
        ); //重新计算放大后的中心坐标
        poscenter = point2D(
          ev.center.x - lastcenter.x,
          ev.center.y - lastcenter.y
        );
        console.log("center", lastcenter.x, lastcenter.y);

        requestElementUpdate("onpinchStart");
      }
      function onPinch(ev) {
        var nowScale = (tMatrix[0] = tMatrix[3] = initScale * ev.scale);
        var composscal = 1 - ev.scale;
        tMatrix[4] = (1 - ev.scale) * poscenter.x + lastTranslate.x;
        tMatrix[5] = (1 - ev.scale) * poscenter.y + lastTranslate.y;
        requestElementUpdate("onpinch");
      }

      function onDoubleTap(ev) {
        duration = ".3s ease all";
        var nowScale = tMatrix[0];
        if (nowScale != 1 || tMatrix[4] != 0) {
          //scale不等于1，要重回1
          tMatrix[0] = tMatrix[3] = 1;
          tMatrix[4] = tMatrix[5] = 0;
        } else {
          var pointer = ev.center;
          var scale = 2;
          tMatrix[0] = tMatrix[3] = scale;
          tMatrix[4] = (1 - scale) * (pointer.x - center.x);
          tMatrix[5] = (1 - scale) * (pointer.y - center.y);
        }
        requestElementUpdate("doubleTap");
      }

      function updateElementTransform() {
        el.style.transition = duration;
        var tmp = tMatrix.join(",");
        console.log(tmp);
        el.style.transform = "matrix(" + tmp + ")";
        ticking = false;
      }

      function requestElementUpdate() {
        arguments && console.log(arguments[0]);
        if (!ticking) {
          reqAnimationFrame(updateElementTransform);
          ticking = true;
        }
      }
      requestElementUpdate();
    }
  }, [previewImageRef.current]);

  const loadPreviewContent = useCallback(
    async (filepath, defaultScale = $defaultScale, _page = 1) => {
      if (filepath) {
        $previewPath = filepath;
      }
      if (!/\.pdf$/.test(filepath)) {
        Tips().create("该文件暂时不支持预览");
        return false;
      }
      setIsOpenPreview(true);
      let task = null;
      if ($previewTask === null) {
        // eslint-disable-next-line
        task = $previewTask = pdfjsLib.getDocument(
          // eslint-disable-next-line
          `${$Global.filePath}/pdf?path=${encodeURIComponent(filepath)}`
        );
      } else {
        task = $previewTask;
      }
      task.promise.then(async (pdf) => {
        setAllPage(pdf.numPages);
        try {
          const page = await pdf.getPage(_page);
          const canvas = document.createElement("canvas");
          var scale = defaultScale;
          var viewport = page.getViewport({ scale });
          var context = canvas.getContext("2d");
          var devicePixelRatio = window.devicePixelRatio || 1;
          var backingStoreRatio =
            context.webkitBackingStorePixelRatio ||
            context.mozBackingStorePixelRatio ||
            context.msBackingStorePixelRatio ||
            context.oBackingStorePixelRatio ||
            context.backingStorePixelRatio ||
            1;
          var ratio = devicePixelRatio / backingStoreRatio;

          canvas.width = viewport.width;
          canvas.height = viewport.height;
          var renderContext = {
            canvasContext: context,
            viewport: viewport,
          };
          page.render(renderContext);
          setPreviewStatus(2);
          let base64 = null;
          let timerout = null;
          let timerInter = null;
          let loadTimes = 0;

          timerInter = setInterval(() => {
            if (base64 !== canvas.toDataURL("image/png")) {
              base64 = canvas.toDataURL("image/png");
              setPreviewImageSrc(canvas.toDataURL("image/png"));
            }
          }, 100);

          timerout = setTimeout(() => {
            clearTimeout(timerInter);
          }, 3000);
        } catch (err) {
          setPreviewStatus(1);
        }
      });
    },
    [previewPage]
  );

  const prevPage = () => {
    setPreviewStatus(0);
    setPreviewImageSrc(null);
    setPreviewPage((pg) => {
      loadPreviewContent($previewPath, $defaultScale, pg - 1);
      return pg - 1;
    });
  };

  const nextPage = () => {
    setPreviewStatus(0);
    setPreviewImageSrc(null);
    setPreviewPage((pg) => {
      loadPreviewContent($previewPath, $defaultScale, pg + 1);
      return pg + 1;
    });
  };

  const previewSizeAdjust = (full) =>
    setPreviewScreen(
      full
        ? {
            width: 1,
            height: "100%",
            borderRadius: 0,
            full: full || defaultPreviewScreen.full,
          }
        : defaultPreviewScreen
    );

  const copyLink = (href) => {
    var input = document.createElement("input");
    input.setAttribute("readonly", "readonly"); // 防止手机上弹出软键盘
    // eslint-disable-next-line
    input.setAttribute("value", $Global.filePath + href);
    document.body.appendChild(input);
    input.select();
    var res = document.execCommand("copy");
    document.body.removeChild(input);
    if (res) {
      Tips().create("复制成功,请前往浏览器下载", "success");
    } else {
      Tips().create("复制失败");
    }
  };

  const downloadFile = (href) => {
    let ele = document.createElement("a");
    ele.target = "_blank";
    ele.href = href;
    ele.download = "test.pdf";
    ele.style.display = "none";
    document.body.appendChild(ele);
    ele.click();
    setTimeout(() => {
      document.body.removeChild(ele);
      ele = null;
    }, 0);
  };

  return (
    <>
      {isOpenPreview && (
        <Box
          position="fixed"
          boxShadow="0 0 10px 0 #eee"
          zIndex={90}
          left={0}
          top={0}
          width={1}
          height="100%"
          bgcolor="rgba(0, 0, 0, 0.5)"
          display="flex"
          flexDirection="row"
          justifyContent="center"
          alignItems="center"
        >
          <Fade in={true}>
            <Box width={1} height="100%" bgcolor="#fff" position="relative">
              <Box
                width={1}
                height="100%"
                p={2}
                boxSizing="border-box"
                display="flex"
                flexDirection="column"
                alignItems="flex-start"
                justifyContent={
                  [0, 1].includes(previewStatus) ? "center" : "flex-start"
                }
                ref={previewBoxRef}
                overflow="auto"
                ß
              >
                {previewStatus === 0 && (
                  <Box
                    position="fixed"
                    left="0"
                    top="0"
                    width={1}
                    height="100%"
                    display="flex"
                    flexDirection="row"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <CircularProgress></CircularProgress>
                  </Box>
                )}
                {previewStatus === 1 && <Box>加载出错</Box>}
                {previewStatus > 1 && (
                  <img
                    src={previewImageSrc}
                    id="previewImage"
                    ref={previewImageRef}
                    style={{
                      display: "inline-block",
                      maxWidth: "100%",
                      background: "red",
                    }}
                    alt=""
                  />
                )}
              </Box>
              <Box
                display="flex"
                position="fixed"
                left={8}
                bottom={10}
                flexDirection="row nowrap"
              >
                <Button
                  variant="contained"
                  disabled={previewPage <= 1}
                  onClick={prevPage}
                  style={{
                    fontSize: window.px2rem(24),
                  }}
                  color="primary"
                >
                  上一页
                </Button>
                <Button
                  variant="contained"
                  disabled={true}
                  onClick={prevPage}
                  style={{
                    fontSize: window.px2rem(24),
                    background: "none",
                    color: "#000",
                  }}
                  color="primary"
                >
                  {previewPage}/{allPage}
                </Button>
                <Button
                  style={{
                    fontSize: window.px2rem(24),
                  }}
                  variant="contained"
                  disabled={previewPage >= allPage}
                  onClick={nextPage}
                  color="primary"
                >
                  下一页
                </Button>
              </Box>
              <Box position="absolute" right={8} bottom={10}>
                <Backspace
                  onClick={() => {
                    setIsOpenPreview(false) && previewSizeAdjust(false);
                    $defaultScale = 3;
                    $previewPath = "";
                    $previewTask = null;
                    setPreviewImageSrc(null);
                    setRequest([]);
                    setPreviewStatus(0)
                    setPreviewPage(1);
                    setAllPage(1);
                  }}
                ></Backspace>
              </Box>
            </Box>
          </Fade>
        </Box>
      )}
      <Box width={1} flexGrow={1} className={stylies.ContentBox}>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="center"
          mx="auto"
          my="10px"
          width={0.95}
        >
          <Grid item xs={12}>
            {(course || []).map((rsr) => {
              return (
                <Accordion key={`${rsr.id}`} className={stylies.Accordion}>
                  <AccordionSummary
                    onClick={() => getResource(rsr.id)}
                    expandIcon={
                      <ExpandMoreIcon
                        style={{
                          width: window.px2rem(50),
                          height: window.px2rem(50),
                        }}
                      />
                    }
                  >
                    <Typography>{rsr.title}</Typography>
                  </AccordionSummary>
                  <AccordionDetails className={stylies.AccordionDetails}>
                    {isRequest.includes(rsr.id) && (
                      <Box
                        width={1}
                        height={window.px2rem(200)}
                        display="flex"
                        flexDirection="row"
                        justifyContent="center"
                        alignItems="center"
                      >
                        <CircularProgress></CircularProgress>
                      </Box>
                    )}
                    <List
                      key={`${rsr.id}_${resourceId}`}
                      className={stylies.List}
                    >
                      {Array.isArray(resource[rsr.id]) &&
                        resource[rsr.id].length === 0 && (
                          <ListItem>
                            <Box
                              width={1}
                              textAlign="center"
                              color="#e5e5e6"
                              fontSize={window.px2rem(28)}
                            >
                              暂无数据
                            </Box>
                          </ListItem>
                        )}
                      {(resource[rsr.id] || []).map((rso) => {
                        return (
                          <ListItem
                            key={`${rso.path}`}
                            className={stylies.ListItem}
                          >
                            <Box
                              width={1}
                              display="flex"
                              justifyContent="flex-start"
                              flexDirection="row"
                            >
                              <Box
                                width={1}
                                className={stylies.TextBox}
                                flexGrow={1}
                                style={{
                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                }}
                              >
                                {rso.name}
                              </Box>
                              {wx ? (
                                <>
                                  <Button
                                    variant="outlined"
                                    className={stylies.PreviewButton}
                                    color="secondary"
                                    size="small"
                                    onClick={() => loadPreviewContent(rso.path)}
                                  >
                                    {" "}
                                    预览
                                  </Button>
                                  <Button
                                    variant="contained"
                                    className={stylies.PreviewButton}
                                    color="primary"
                                    size="small"
                                    onClick={() => copyLink(rso.path)}
                                  >
                                    复制链接
                                  </Button>
                                </>
                              ) : (
                                <>
                                  <Button
                                    variant="outlined"
                                    className={stylies.PreviewButton}
                                    color="secondary"
                                    size="small"
                                    onClick={() => loadPreviewContent(rso.path)}
                                  >
                                    {" "}
                                    预览
                                  </Button>
                                  <Button
                                    variant="contained"
                                    className={stylies.Button}
                                    color="primary"
                                    size="small"
                                    onClick={() =>
                                      // eslint-disable-next-line
                                      downloadFile($Global.filePath + rso.path)
                                    }
                                  >
                                    下载
                                  </Button>
                                </>
                              )}
                            </Box>
                          </ListItem>
                        );
                      })}
                    </List>
                  </AccordionDetails>
                </Accordion>
              );
            })}
          </Grid>
        </Box>
      </Box>
    </>
  );
}

export default memo(Home);
