const USERINFO = "u_user_info"

class Info {
  static save(info) {
    if (Object.prototype.toString.call(info) === "[object Object]") {
      for (let key in info) {
        if (info[key] === "暂无") {
          info[key] = null
        }
      }
    }
    window[USERINFO] = Object.assign({}, info || {})
  }

  static get() {
    return window[USERINFO]
  }
  static getName() {
    if (window[USERINFO]) {
      return window[USERINFO].nick_name
    }
    return null
  }
  static getPortrait() {
    if (window[USERINFO]) {
      // eslint-disable-next-line
      return $Global.image + window[USERINFO].portrait
    }
    return null
  }
  static remove() {
    window[USERINFO] = null
  }
}

export default Info
