import React, { memo, useState } from "react"
import { withRouter } from "react-router-dom"
import { makeStyles } from "@material-ui/core/styles"
import BottomNavigation from "@material-ui/core/BottomNavigation"
import Box from "@material-ui/core/Box"
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction"

import ListOfClass from "./components/ListOfClass"
import ListOfMaterial from "./components/ListOfMaterial"
import PersonInfo from "./components/PersonInfo"

import RestoreIcon from "@material-ui/icons/Restore"
import BookmarksOutlined from "@material-ui/icons/BookmarksOutlined"
import AccountCircleOutlined from "@material-ui/icons/AccountCircleOutlined"

const useStyle = makeStyles((theme) => ({
  BottomNavigation: {
    height: "auto",
  },
  List: {
    width: "100%",
    margin: "0 auto",
  },
  ListItem: {
    background: "#fff",
    marginTop: "10px",
  },
  ContentBox: {
    overflow: "auto",
    background: "#e5e5e6",
  },
  Typography: {
    fontSize: theme.props.px2rem(34),
  },
}))

function Home(props) {
  const stylies = useStyle()
  const [activeNavigation, setActiveNavigation] = useState(+props.match.params.id || 0)

  return (
    <>
      <Box height="100%" display="flex" flexDirection="column" flexWrap="nowrap" justifyContent="center" alignItems="flex-start">
        <Box width={1} p={2} fontSize={window.px2rem(28)} boxSizing="border-box" textAlign="center">
          {activeNavigation === 0 && "我的课程"}
          {activeNavigation === 1 && "资料库"}
          {activeNavigation === 2 && "我的信息"}
        </Box>
        <Box width={1} flexGrow={1} bgcolor="green" className={stylies.ContentBox}>
          <Box width={1} paddingBottom={window.px2rem(170)} position="relative" display="flex" alignItems="flex-start" justifyContent="center">
            {activeNavigation === 2 && <PersonInfo></PersonInfo>}
            {activeNavigation === 1 && <ListOfMaterial></ListOfMaterial>}
            {activeNavigation === 0 && <ListOfClass></ListOfClass>}
          </Box>
        </Box>
        <Box width={1}>
          <BottomNavigation
            showLabels
            className={stylies.BottomNavigation}
            value={activeNavigation}
            onChange={(event, newValue) => {
              setActiveNavigation(newValue)
              window.location.hash = `/home/${newValue}?r=${Date.now()}`
            }}
          >
            <BottomNavigationAction
              label="课程"
              icon={
                <RestoreIcon
                  style={{
                    width: window.px2rem(50),
                    height: window.px2rem(50),
                  }}
                ></RestoreIcon>
              }
            ></BottomNavigationAction>
            <BottomNavigationAction
              label="资料库"
              icon={
                <BookmarksOutlined
                  style={{
                    width: window.px2rem(40),
                    height: window.px2rem(40),
                  }}
                ></BookmarksOutlined>
              }
            ></BottomNavigationAction>
            <BottomNavigationAction
              label="我的"
              icon={
                <AccountCircleOutlined
                  style={{
                    width: window.px2rem(50),
                    height: window.px2rem(50),
                  }}
                ></AccountCircleOutlined>
              }
            ></BottomNavigationAction>
          </BottomNavigation>
        </Box>
      </Box>
    </>
  )
}

export default memo(withRouter(Home))
