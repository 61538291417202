import React, { useState } from "react";
import ReactDOM from "react-dom"
// import ImageWx from "../assets/wx.png"
import "./style.scss"
import classnames from "classnames"

const hours = [...new Array(10)]
const seconds = [...new Array(60)]

const zero = num => num >= 10 ? num : `0${num}`

function PopupDate({ remove, date = '12:00', cb = function () {} }) {

    const [uhours, useconds] = date.split(':')
    const [uuhours, setUuhours] = useState(uhours)
    const [uuseconds, setUuseconds] = useState(useconds)

    const suretime = () => {
        console.log(uuhours, uuseconds)
        cb(uuhours, uuseconds)
        remove()
    }

    return (
        <div className="popup-wx">
            <div className="popup-mask" onClick={remove}></div>
            <div className="popup-content-date">
               <header className="date-header">
                   <span onClick={remove}>取消</span>
                   <span onClick={suretime}>确定</span>
               </header>
               <main className=" date-content">
                   <aside className="date-item hours">
                       {
                           hours.map((item, index) => <p 
                            onClick={() => setUuhours(zero(index))} 
                            className={classnames({
                               active: +uuhours == zero(index) // eslint-disable-line
                           })}>{zero(index)}</p>)
                       }
                   </aside>
                   <section className="date-item seconds">
                    {
                           seconds.map((item, index) => <p onClick={() => setUuseconds(zero(index))} className={classnames({
                               active: +uuseconds == zero(index) // eslint-disable-line
                           })}>{zero(index)}</p>)
                       }
                   </section>
               </main>
            </div>
        </div>
    )
}

const popup = {
    create(options) {
        const el = document.createElement("div")
        document.body.appendChild(el)

        const remove = () => {
            if (el) {
                document.body.removeChild(el)
            }
        }

        ReactDOM.render(<PopupDate remove={remove} {...options}></PopupDate>, el)
    }
}

export default popup
