import React, { memo, useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Chip from "@material-ui/core/Chip";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import MovieCreation from "@material-ui/icons/MovieCreation";
import AccessTime from "@material-ui/icons/AccessTime";
import Airplay from "@material-ui/icons/Airplay";
import CircularProgress from "@material-ui/core/CircularProgress";
import dayjs from "dayjs"

import IconButton from "@material-ui/core/IconButton";
import ArrowBackIos from "@material-ui/icons/ArrowBackIos";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Tips from "../../components/Tips";

import LiveTv from "@material-ui/icons/LiveTv";

const useStyle = makeStyles(({ props, palette }) => ({
  BottomNavigation: {
    height: "auto",
  },
  RoomListItem: {
    // height: props.px2rem(180),
  },
  List: {
    width: "100%",
    margin: "0 auto",
  },
  TextBox: {
    overflow: "hidden",
    fontSize: props.px2rem(28),
  },
  ListItem: {
    background: "#fff",
    marginTop: "10px",
    overflow: "hidden",
  },
  Button: {
    fontSize: props.px2rem(18),
    whiteSpace: "nowrap",
    marginLeft: props.px2rem(4),
  },
  ContentBox: {
    overflow: "auto",
    background: "#e5e5e6",
  },
  AccordionDetails: {
    padding: 0,
    display: "block",
  },
  Accordion: {
    width: "100%",
  },
  AccessTime: {
    width: `${props.px2rem(32)} !important`,
    height: `${props.px2rem(32)} !important`,
    "& + span": {
      fontSize: props.px2rem(24),
      marginLeft: props.px2rem(4),
    },
  },
  Paper: {
    background: "#eee",
    borderRadius: "8px",
  },
}));

function Content(props) {
  const stylies = useStyle();
  const search = window.location.search.split("&")
  const [, courseID] = search[0].split("=");
  const [, courseTitle] = search[1].split("=");
  const [video, setVideo] = useState([]);
  const [live, setLive] = useState([]);
  const [tts, setTts] = useState(Date.now())
  const [isRequest, setRequest] = useState([-1, -1]);

  const getVideo = async () => {
    if (video.length > 0) {
      return false;
    }
    try {
      setRequest((state) => [0].concat(state.slice(1)));
      const { error, data } = await $Api.default.getCourseDetail({
        type: "video",
        id: courseID,
      });
      const { code, msg } = error;
      if (code) throw { message: msg };
      setVideo(data || []);
      setRequest((state) => [-1].concat(state.slice(1)));
    } catch (err) {
      Tips().create(err.message);
    }
  };

  const getLive = async () => {
    if (live.length > 0) {
      return false;
    }
    try {
      setRequest((state) => state.slice(1).concat([0]));
      // eslint-disable-next-line
      const { error, data: { list, ts } } = await $Api.default.getCourseDetail({
        type: "live",
        id: courseID,
      });
      const { code, msg } = error;
      if (code) throw { message: msg };
      setLive(list || []);
      setTts(ts)
      setRequest((state) => state.slice(0, 1).concat([-1]));
    } catch (err) {
      Tips().create(err.message);
    }
  };

  return (
    <>
      <Box
        fontSize={window.px2rem(32)}
        p={2}
        boxSizing="border-box"
        textAlign="center"
        width={1}
        position="relative"
      >
        <Box
          position="absolute"
          top={1}
          left={1}
          onClick={() => props.history.replace("/")}
        >
          <IconButton aria-label="delete" disabled color="primary">
            <ArrowBackIos
              style={{
                width: window.px2rem(50),
                height: window.px2rem(50),
              }}
            />
          </IconButton>
        </Box>
        {decodeURIComponent(courseTitle)}
      </Box>
      <Box
        height="100%"
        display="flex"
        flexDirection="column"
        flexWrap="nowrap"
        justifyContent="center"
        alignItems="flex-start"
        overflow="auto"
      >
        <Box width={1} flexGrow={1} className={stylies.ContentBox}>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            width={0.95}
            mx="auto"
            mt={1}
          >
            <Box width={1}>
              <Accordion className={stylies.Accordion}>
                <AccordionSummary
                  onClick={getVideo}
                  expandIcon={
                    <ExpandMoreIcon
                      style={{
                        width: window.px2rem(50),
                        height: window.px2rem(50),
                      }}
                    />
                  }
                >
                  <Chip
                    label="精品录播课"
                    color="secondary"
                    icon={<LiveTv></LiveTv>}
                  ></Chip>
                </AccordionSummary>
                <AccordionDetails className={stylies.AccordionDetails}>
                  {isRequest[0] === 0 && (
                    <Box
                      width={1}
                      height={window.px2rem(200)}
                      display="flex"
                      flexDirection="row"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <CircularProgress></CircularProgress>
                    </Box>
                  )}
                  <List className={stylies.List}>
                    {Array.isArray(video) && video.length === 0 && isRequest[0] !== 0 && <Box
                              width={1}
                              textAlign="center"
                              color="#e5e5e6"
                              fontSize={window.px2rem(28)}
                            >
                              暂无数据
                            </Box>}
                    {(video || []).map((vid) => (
                      <ListItem
                        key={`${vid.title}`}
                        className={stylies.ListItem}
                      >
                        <Box
                          width={1}
                          display="flex"
                          justifyContent="flex-start"
                          flexDirection="row"
                          alignItems="center"
                        >
                          <MovieCreation
                            style={{
                              width: window.px2rem(30),
                              height: window.px2rem(30),
                              verticalAlign: "middle"
                            }}
                          ></MovieCreation>
                          <Box
                            width={1}
                            className={stylies.TextBox}
                            flexGrow={1}
                            position="relative"
                            top="-2px"
                          >
                            {vid.title}
                          </Box>
                          <Button
                            variant="contained"
                            className={stylies.Button}
                            color="primary"
                            size="small"
                            onClick={() => window.open(vid.video_url)}
                          >
                            观看视频
                          </Button>
                        </Box>
                      </ListItem>
                    ))}
                  </List>
                </AccordionDetails>
              </Accordion>
            </Box>
            <Box width={1} mt={1}>
              <Accordion className={stylies.Accordion}>
                <AccordionSummary
                  onClick={getLive}
                  expandIcon={
                    <ExpandMoreIcon
                      style={{
                        width: window.px2rem(50),
                        height: window.px2rem(50),
                      }}
                    />
                  }
                >
                  <Chip
                    label="直播课"
                    color="primary"
                    icon={<Airplay></Airplay>}
                  ></Chip>
                </AccordionSummary>
                <AccordionDetails className={stylies.AccordionDetails}>
                  {isRequest[1] === 0 && (
                    <Box
                      width={1}
                      height={window.px2rem(200)}
                      display="flex"
                      flexDirection="row"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <CircularProgress></CircularProgress>
                    </Box>
                  )}
                  <List className={stylies.List}>
                  {Array.isArray(live) && live.length === 0 && isRequest[1] !== 0 && <Box
                              width={1}
                              textAlign="center"
                              color="#e5e5e6"
                              fontSize={window.px2rem(28)}
                            >
                              暂无数据
                            </Box>}
                    {(live || []).map((liv) => (
                      <ListItem
                        key={`${live.title}`}
                        className={stylies.RoomListItem}
                      >
                        {/* <Box width={0.4}>
                        <Box width={1} pt="60%" className={stylies.Paper}></Box>
                      </Box> */}
                        <Box
                          width={1}
                          pl="10px"
                          height="100%"
                          display="flex"
                          flexDirection="column"
                          justifyContent="space-between"
                        >
                          <Typography noWrap>{liv.title}</Typography>
                          <Box
                            width={1}
                            display="flex"
                            justifyContent="space-between"
                            flexDirection="row"
                          >
                            <Typography
                              color="textSecondary"
                              noWrap
                              component="time"
                            >
                              <AccessTime
                                className={stylies.AccessTime}
                                style={{
                                  verticalAlign: "middle",
                                }}
                              ></AccessTime>
                              <span
                                style={{
                                  fontSize: window.px2rem(24),
                                }}
                              >
                                {dayjs(liv._start_time * 1000).format('YYYY-MM-DD HH:mm:ss')} ~ {dayjs(liv._end_time * 1000).format('YYYY-MM-DD HH:mm:ss')}
                              </span>
                            </Typography>
                            {
                              (liv.video_url || tts > liv._end_time) &&
                              <Button
                                variant="outlined"
                                className={stylies.Button}
                                color="primary"
                                disabled={!liv.video_url}
                                size="small"
                                onClick={() => window.open(liv.video_url)}
                              >观看回放</Button>
                            }
                            {
                            (liv.url || tts < liv._start_time) &&
                            <Button
                              variant="outlined"
                              className={stylies.Button}
                              color="primary"
                              disabled={!liv.url}
                              size="small"
                              onClick={() => window.open(liv.url)}
                            >观看直播</Button>
                            }
                          </Box>
                        </Box>
                      </ListItem>
                    ))}
                  </List>
                </AccordionDetails>
              </Accordion>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default memo(withRouter(Content));
