import React from "react";
import ReactDOM from "react-dom";
import { HashRouter } from "react-router-dom";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";

import Token from "../utils/token";
import Info from "../utils/info";

const router = new HashRouter();

function AlignLogin() {
  return {
    create() {
      Token.remove()
      Info.remove()

      const ele = document.createElement("div");
      document.body.appendChild(ele);
      const alignLoginEvt = () => {
        if (ele) {
          document.body.removeChild(ele)
          router.history.replace("/login");
        }
        // setTimeout(() => {
        //   window.location.reload();
        // }, 0);
      };
      ReactDOM.render(
        <Box
          position="fixed"
          bgcolor="rgba(0, 0, 0, 0.5)"
          left="0"
          top="0"
          width={1}
          height="100%"
          display="flex"
          flexDirection="row"
          justifyContent="center"
          alignItems="center"
        >
          <Box
            width={window.px2rem(420)}
            p={window.px2rem(20)}
            boxSizing="border-box"
            // minHeight={window.px2rem(280)}
            borderRadius="10px"
            bgcolor="#fff"
          >
            <Box textAlign="center" mb={window.px2rem(40)}>
              {/* <ErrorOutline
                style={{
                  verticalAlign: "middle",
                  color: "red",
                  width: `${window.px2rem(30)} !important`,
                  height: `${window.px2rem(30)} !important`,
                }}
                ></ErrorOutline> */}
              <span style={{
                verticalAlign: "middle",
                fontSize: window.px2rem(24),
                marginLeft: window.px2rem(10)
              }}>身份信息已过期，请重新登录</span>
            </Box>
            <Button
                color="primary"
                variant="contained"
                onClick={alignLoginEvt}
                style={{
                  fontSize: window.px2rem(24),
                  width: "80%",
                  marginLeft: "10%"
                }}
              >
                重新登录
              </Button>
            </Box>
        </Box>,
        ele
      );
    },
  };
}

export default AlignLogin;
