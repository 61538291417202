import React from "react";
import ReactDOM from "react-dom";
import CircularProgress from "@material-ui/core/CircularProgress";
import Box from "@material-ui/core/Box";

function Loading() {
  let ele = null;

  return {
    remove() {
      if (ele) {
        document.body.removeChild(ele);
      }
    },
    create() {
      ele = document.createElement("div");
      document.body.appendChild(ele);
      const InnerLoading = () => (
        <Box
          width={1}
          height="100%"
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="center"
          bgcolor="rgba(0,0,0,.5)"
          position="fixed"
          left={0}
          top={0}
        >
          <CircularProgress></CircularProgress>
        </Box>
      );
      ReactDOM.render(<InnerLoading></InnerLoading>, ele);

      return {
        remove() {
          if (ele) {
            document.body.removeChild(ele);
          }
        },
      };
    },
  };
}
export default Loading;
