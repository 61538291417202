const TOKEN = "u_token"
class Token {
  static save(token) {
    window.localStorage.setItem(TOKEN, token)
  }
  static get() {
    return window.localStorage.getItem(TOKEN) || null
  }
  static remove() {
    window.localStorage.removeItem(TOKEN)
  }
}
export default Token